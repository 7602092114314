<template>
    <div
        ref="slider"
        class="a-slider"
        :class="className"
    >
        <ul class="a-slider__wrapper">
            <slot />
        </ul>

        <div v-if="showControls" class="a-slider__nav">
            <ul v-if="options.showPagination" class="a-slider__dots">
                <li v-for="i in sliderLength" :key="i" class="a-slider__dot" />
            </ul>

            <div v-if="options.showNavigation" class="a-slider__controls">
                <button class="a-slider__control a-slider__control_prev">
                    <a-glyph class="a-slider__control-arrow" :name="options.glyphs?.left || 'icon-pointer-left'" />
                </button>

                <button class="a-slider__control a-slider__control_next">
                    <a-glyph class="a-slider__control-arrow" :name="options.glyphs?.right || 'icon-pointer-right'" />
                </button>
            </div>

            <div v-if="options.showCounter" class="a-slider__counter">
                <div class="a-slider__dots" />
            </div>
        </div>

        <div
            v-if="options.useBarsPagination"
            class="a-slider__nav a-slider__nav--bars"
            :class="barsClasses"
            :style="cssVars"
        >
            <template v-if="showCompactLayout">
                <div ref="paginationWrapper" class="pagination-items pagination-items--compact" />
                <div v-if="options.showNavigation" class="compact-wrapper">
                    <button class="a-slider__control a-slider__control_prev">
                        <a-glyph class="a-slider__control-arrow" name="icon-pointer-left" />
                    </button>
                    <button class="a-slider__control a-slider__control_next">
                        <a-glyph class="a-slider__control-arrow" name="icon-pointer-right" />
                    </button>
                </div>
            </template>
            <template v-else>
                <button v-if="options.showNavigation" class="a-slider__control a-slider__control_prev">
                    <a-glyph class="a-slider__control-arrow" name="icon-pointer-left" />
                </button>

                <div ref="paginationWrapper" class="pagination-items" />

                <button v-if="options.showNavigation" class="a-slider__control a-slider__control_next">
                    <a-glyph class="a-slider__control-arrow" name="icon-pointer-right" />
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';

const AUTOPLAY_DEFAULT_DELAY_SEC = 4;
const MIN_SLIDES_FOR_COMPACT_LAYOUT = 7;
const DEFAULT_CHANGE_SPEED_MS = 550;

const BARS_CLASS = 'pagination-item';
const BARS_ACTIVE_CLASS = 'pagination-item--active';

export default {
    name: 'ASlider',

    components: {
        AGlyph,
    },

    mixins: [breakpoint],

    provide() {
        return {
            slideWithBorder: this.slideWithBorder,
        };
    },

    props: {
        options: {
            type: Object,
            default: () => ({}),
        },

        slideWithBorder: {
            type: Boolean,
            default: false,
        },

        theme: {
            type: String,
            default: 'light',
            validator: (val) => ['light', 'dark'].includes(val),
        },

        paginationAlignment: {
            type: String,
            default: 'left',
            validator: (val) => ['left', 'center', 'right'].includes(val),
        },
    },

    emits: ['change'],

    data() {
        return {
            activeSlideProgress: this.options.useAutoplay ? -100 : 0,
        };
    },

    computed: {
        barsClasses() {
            const alignment = this.showCompactLayout ? 'left' : this.paginationAlignment;
            return [
                `a-slider__nav--align-${alignment}`,
                { 'a-slider__nav--compact': this.showCompactLayout },
            ];
        },

        showCompactLayout() {
            return this.isMobile && this.sliderLength > MIN_SLIDES_FOR_COMPACT_LAYOUT;
        },

        showControls() {
            const options = this.options;
            if (options.useBarsPagination) return false;
            return options.showPagination || options.showNavigation || options.showCounter;
        },

        sliderLength() {
            return this.$slots.default ? this.$slots.default.filter((s) => s.tag).length : 0;
        },

        className() {
            return {
                'a-slider_theme_dark': this.theme === 'dark',
                'a-slider_theme_light': this.theme === 'light',
            };
        },

        cssVars() {
            const progress = this.options.useAutoplay ? this.activeSlideProgress : 0;
            const res = {
                '--active-slide-progress': `${progress}%`,
            };

            if (this.options.useBarsPagination) {
                res['--swap-speed'] = `${this.options.speed || DEFAULT_CHANGE_SPEED_MS}ms`;
            }

            return res;
        },

        getPaginationOptions() {
            if (this.options.useBarsPagination) return this.getBarsPaginationOptions;
            if (this.options.showPagination || this.options.showCounter) return this.getDefaultPaginationOptions;
            return {};
        },

        getBarsPaginationOptions() {
            return {
                el: this.$refs.paginationWrapper,
                type: 'bullets',
                bulletClass: BARS_CLASS,
                bulletActiveClass: BARS_ACTIVE_CLASS,
                clickable: true,
                renderBullet: (index, className) => `<div class="${className}"><div class="inner"></div></div>`,
                ...this.options.customPagination,
            };
        },

        getDefaultPaginationOptions() {
            return {
                el: this.$refs.slider.querySelector('.a-slider__dots'),
                type: this.options.paginationType || 'fraction',
                bulletElement: 'li',
                bulletClass: 'a-slider__dot',
                bulletActiveClass: 'a-slider__dot_active',
                clickable: true,
                currentClass: 'a-slider__counter-current',
                totalClass: 'a-slider__counter-total',
                renderFraction: (currentClass, totalClass) => `
                        <span class="${currentClass}"></span>
                        <span class="a-slider__counter-text">${this.options.counterText || 'of'}</span>
                        <span class="${totalClass}"></span>
                    `,
                ...this.options.customPagination,
            };
        },
    },

    async mounted() {
        if (this.options.skipInit) return;
        await this.$nextTick(); // await to properly bind button controls for compact layout
        this.initSwiper();
    },

    beforeDestroy() {
        this.$refs.slider?.removeEventListener('click', this.handleSlideClick);
        this.$refs.slider?.removeEventListener('mouseenter', this.handleWrapperMouseEnter);
        this.$refs.slider?.removeEventListener('mouseleave', this.handleWrapperMouseLeave);
        this.swiper?.destroy();
    },

    methods: {
        initSwiper() {
            const swiperOptions = {
                modules: [Navigation, Pagination],
                wrapperClass: 'a-slider__wrapper',
                slideClass: 'a-slide',
                slideActiveClass: 'a-slide_active',
                slideNextClass: 'a-slide_next',
                slideToClickedSlide: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                watchOverflow: false,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: '.a-slider__control_next',
                    prevEl: '.a-slider__control_prev',
                    disabledClass: 'a-slider__control_disabled',
                },
                pagination: this.getPaginationOptions,
                ...this.options,
                on: {
                    afterInit: this.onSwiperInit,
                    slideChange: this.onSlideChange,
                    ...this.options.on,
                },
                loop: !!this.options.useLoop,
            };

            if (this.options.useAutoplay) {
                this.attachAutoplayOptions(swiperOptions);
            }

            this.swiper = new Swiper(this.$refs.slider, swiperOptions);

            this.attachSmallCardsListeners();
        },

        attachAutoplayOptions(swiperOptions) {
            const delay = this.options.slideChangeSeconds || AUTOPLAY_DEFAULT_DELAY_SEC;
            swiperOptions.modules.push(Autoplay);
            // eslint-disable-next-line no-param-reassign
            swiperOptions.autoplay = {
                delay: delay * 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            };
            // eslint-disable-next-line no-param-reassign
            swiperOptions.on.autoplayTimeLeft = (instance, time, progress) => {
                this.activeSlideProgress = -100 + (1 - progress) * 100;
            };

            this.$refs.slider?.addEventListener('mouseenter', this.handleWrapperMouseEnter, true);
            this.$refs.slider?.addEventListener('mouseleave', this.handleWrapperMouseLeave, true);
        },

        handleWrapperMouseEnter(event) {
            if (!event.target.classList.contains('a-slider__wrapper')) return;
            this.swiper?.autoplay.pause();
        },

        handleWrapperMouseLeave(event) {
            if (!event.target.classList.contains('a-slider__wrapper')) return;
            this.swiper?.autoplay.resume();
        },

        attachSmallCardsListeners() {
            if (this.options.sliderType !== 'card-small') return;
            this.$refs.slider?.addEventListener('click', this.handleSlideClick, true);
        },

        handleSlideClick(event) {
            if (!event.target.classList.contains('a-slide')) return;
            this.swiper?.slideNext();
        },

        onSwiperInit(instance) {
            const sliderElement = this.$refs.slider;

            if (this.options.sendEvent) {
                // eslint-disable-next-line no-param-reassign
                instance.previousIndex = instance.activeIndex;
            }

            // Hack for https://github.com/nolimits4web/swiper/issues/4052
            // Unfortunately proposed solutions don't work
            // Instead, we check if all slides are fully visible and disable buttons manually in this case
            window.setTimeout(() => {
                const slides = [...sliderElement.querySelectorAll('.a-slide')];
                const fullFit = slides.every((slide) => slide.classList.contains('swiper-slide-fully-visible'));
                if (fullFit) {
                    const buttons = [...sliderElement.querySelectorAll('.a-slider__control')];
                    buttons.forEach((button) => button.classList.add('a-slider__control_disabled'));
                }
            }, 500);
        },

        onSlideChange(instance) {
            this.$emit('change', instance.activeIndex);
            if (!this.options.sendEvent) return;
            // eslint-disable-next-line no-param-reassign
            instance.previousIndex = instance.activeIndex;
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-slider {
    position: relative;
    width: 100%;
    overflow: hidden;

    &:not(.a-slider_off) {
        .a-slider__wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            transition-property: transform;
            box-sizing: content-box;
            transform: translate3d(0, 0, 0);
        }
        &:deep(.a-slide) {
            flex-shrink: 0;
            transition: opacity 0.2s ease-in-out;
            user-select: none;
        }
    }

    &__nav {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;

        &--bars {
            gap: 20px;

            @media (--viewport-mobile-wide) {
                gap: 32px;
            }

            .a-slider__control {
                margin: 0;
            }
        }

        &--align-left {
            justify-content: flex-start;
        }

        &--align-center {
            justify-content: center;
        }

        &--align-right {
            justify-content: flex-end;
        }

        &--compact {
            flex-direction: column;
            justify-content: initial;
            gap: 16px;
            padding: 0 16px;

            .compact-wrapper {
                display: flex;
                gap: 16px;
            }
        }
    }

    &__content {
        display: flex;
    }

    &__dots {
        display: flex;
        align-items: center;
        margin: 0 -4px;
    }

    &__controls {
        margin: 0 -12px;
        display: flex;
    }

    &__control {
        margin: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: 1px solid var(--av-button-secondary);
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0);
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;

        @media (--viewport-mobile-wide) {
            width: 48px;
            height: 48px;
        }

        &:hover {
            background-color: var(--el-secondary-hover);
        }

        &:active {
            background-color: var(--el-secondary-active);
        }

        &_disabled {
            cursor: default;
            border-color: var(--av-button-secondary-light);

            .a-slider__control-arrow {
                fill: var(--av-button-secondary-light);
            }

            &:hover,
            &:active,
            &:focus {
                background: transparent;
            }
        }
    }

    &__control-arrow {
        fill: var(--av-button-secondary);
        transition: fill 0.2s ease-in-out;
    }

    &_theme {
        &_dark {
            .a-slider__dot {
                &::before {
                    background-color: var(--av-brand-secondary);
                }

                &_active {
                    &::before {
                        background-color: var(--av-inversed-primary);
                    }
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &__controls {
            margin: 0 -12px;
        }
    }

    @media (--viewport-desktop) {
        &__control_next {
            display: flex;

            &:hover {
                fill: var(--av-nav-primary);
            }

            &:focus {
                outline: none;
            }
        }
    }
}

/* This part has been moved from leaked webinar.vue slice; TODO: check if it works as intended */
.a-slider {
    &__counter {
        &-total,
        &-text,
        &-current {
            color: var(--av-fixed-light);
            font-size: 18px;
        }
    }

    /* Swiper.js has a bug with looped slides when there are only 2 slides
     * when the same slide is visible 2 times, "next" button doesnt generate additional looped slides
     * Solution: only when there are 2 slides, give them each 50% width on desktop+
     */
    &[data-slides='2'] {
        .a-slide {
            @media (--viewport-desktop) {
                width: 50%;
            }
        }
    }
}

.slide-storybook {
    background: #d0caca;
    text-align: center;
    padding: 24px 48px;
}

/* new pagination */
.pagination-items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:deep(.pagination-item) {
        position: relative;
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        will-change: width;
        transition: width var(--swap-speed) ease;

        @media (--viewport-mobile-wide) {
            width: 48px;
        }

        .inner {
            overflow: hidden;
            width: 100%;
            height: 8px;
            border-radius: 4px;
            background: var(--el-secondary-active);
        }

        &--active {
            width: 40px;

            @media (--viewport-mobile-wide) {
                width: 96px;
            }

            .inner:after {
                content: '';
                display: block;
                border-radius: 4px;
                width: 100%;
                height: 100%;
                background: var(--av-brand-primary);
                will-change: transform;
                transform: translateX(var(--active-slide-progress));
            }
        }
    }

    &--compact {
        justify-content: flex-start;
    }
}

.a-slider_theme_dark {
    background: var(--av-nav-primary);
    padding-bottom: 56px;

    @media (--viewport-mobile-wide) {
        padding-bottom: 64px;
    }

    @media (--viewport-tablet) {
        padding-bottom: 48px;
    }

    .pagination-items {
        &:deep(.pagination-item) {
            .inner {
                background: #677994;
            }

            &:not(.pagination-item--active) {
                &:hover {
                    .inner {
                        background: var(--av-inversed-light);
                    }
                }
            }

            &--active {
                .inner:after {
                    background: #f0f2f4;
                }
            }
        }
    }

    .a-slider__control {
        border-color: var(--av-button-inversed);

        &-arrow {
            fill: var(--av-button-inversed);
        }

        &:hover {
            background-color: var(--av-button-inversed-hover);
        }

        &:active {
            background-color: var(--av-button-inversed-active);
        }

        &_disabled {
            border-color: var(--av-button-inversed-light);

            &:hover,
            &:active,
            &:focus {
                background: transparent;
            }

            .a-slider__control-arrow {
                fill: var(--av-button-inversed-light);
            }
        }
    }

    .a-slider__counter {
        color: var(--av-inversed-primary);
    }
}
</style>

<style lang="postcss">
/*
 * It looks like Swiper doesn't reuse existing markup for &__dot and &__counter_* we put in template.
 * Therefore scoped styles will never apply just because Swiper doesn't render IDs for it for obvious reasons.
 * Given that Vue doesn't actually provide a public API for these IDs (see https://github.com/vuejs/vue-loader/issues/851)
 * we have nothing left but to un-scope them.
 * TODO: is it possible to do it better? Otherwise we'll have to have an unscoped block in every component which wants to override this
 */
.a-slider {
    li.a-slider__dot {
        position: relative;
        width: 16px;
        height: 16px;
        margin: 0 4px;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            inset-inline-start: 50%;
            width: 6px;
            height: 6px;
            transform: translate(-50%, -50%);
            background-color: var(--av-brand-secondary);
            border-radius: 100%;
        }

        &:focus {
            outline: none;
        }

        &_active {
            &::before {
                background-color: var(--av-inversed-primary);
            }
        }
    }

    .a-slider__counter {
        margin-inline-start: 24px;
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 29px;
    }

    .a-slider__counter-current {
        font-weight: 600;
    }

    .a-slider__counter-text {
        margin: 0 8px;
    }

    .a-slider__counter-totals {
        margin: 0 8px;
    }
}
[dir="rtl"] {
    .a-slider__control-arrow {
        transform: rotate(180deg);
    }
}
</style>
